import http from "../../utils/http"



/**
 * 获取文章分类列表(最新)
 */
export function articleCategoryList(params) {
    return http({
        url: "/fupin/api/articleCategory/lists",
        data: params
    })
}

/**
 * 获取文章列表(最新)
 */
export function articleLists(params) {
    return http({
        url: "/fupin/api/article/lists",
        data: params
    })
}


/**
 * 获取文章列表
 */
export function articleList(params) {
    return http({
        url: "/fupin/api/article/lists",
        data: params
    })
}
/**
 * 获取文章详情
 */
export function articleDetail(params) {
    return http({
        url: "/fupin/api/article/detail",
        data: params
    })
}
/**
 * 获取文章列表
 */
export function articleClassifyList(params) {
    return http({
        url: "fupin/api/articleCategory/lists",
        data: params
    })
}
/**
 * 获取文章列表
 */
export function categoryArticleList(params) {
    return http({
        url: "fupin/api/article/category_article_list",
        data: params
    })
}
