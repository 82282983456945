<template>
  <div class="help-other">
    <div class="item-info" v-if="articleList.length">
      <div
        class="item"
        v-for="(item, index) in articleList"
        :key="index"
        @click="detail(item.id)"
      >
        <div class="item-title">{{ item.title }}</div>
        <div class="info">
          <div class="time">{{ item.create_time}}</div>
        </div>
      </div>
      <div class="pager">
        <el-pagination
                background
                :pager-count="5"
                :total="total"
                prev-text="上一页"
                next-text="下一页"
                :current-page.sync="currentPage"
                :page-size.sync="pageSize"
                @size-change="handlePageSizeChange"
                @current-change="handleCurrentPageChange"
        ></el-pagination>
      </div>
    </div>
    <div class="item-info empty" v-else>
      <div class="ns-text-align">暂无资讯!</div>
    </div>
  </div>
</template>

<script>
import { articleClassifyList, articleList } from "@/api/cms/article";
export default {
  name: "articlelist_other",
  components: {},
  data: () => {
    return {
      articleList: [],
      id: null,
      level: null,
      total: 100,
      currentPage: 1,
      pageSize:6,
    };
  },
  created() {
    this.id = this.$route.path.replace("/cms/article/listother-", "");
    this.level = this.$route.query.level;
    this.getInfo();
  },
  watch: {
    $route(curr) {
      this.id = curr.params.pathMatch;
      this.getInfo();
    }
  },
  methods: {
    detail(id) {
      this.$router.push({ path: "/cms/article-" + id });
    },
    getInfo() {
      articleList({
        category_id: this.id,
        level: this.level,
        page: this.currentPage,
        page_size: this.pageSize,
      })
        .then(res => {
          if (res.code == 0 && res.data) {
            this.total = parseInt(res.data.count)
            this.articleList = res.data.list;
          } else {
            this.$router.push({ path: "/cms/article" });
          }
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    handlePageSizeChange(size) {
      this.pageSize = size
      this.getInfo()
    },
    handleCurrentPageChange(page) {
      this.currentPage = page
      this.getInfo()
    },
  }
};
</script>
<style lang="scss" scoped>
.help-other {
  .item-info {
    padding: 10px;
    background-color: #ffffff;
    min-height: 300px;
    border: 1px solid #e9e9e9;
    .item {
      border-bottom: 1px #f1f1f1 solid;
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
      &:last-child {
        border-bottom: none;
      }
      &:first-child {
        padding-top: 0px;
      }
      .item-title {
        font-size: $ns-font-size-base;
        color: #333333;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;
        &:hover {
          color: $base-color;
        }
      }
    }
  }
}
.pager {
   text-align: center;
  margin: 15px 0;
 }
</style>
